(function($) {
  Drupal.behaviors.styleguide_multi_use_mpp_v1 = {
    attach: function(context) {
      $('.main-content', context).once(function(idx, element) {
        var $context = $(element);
        var $firstAutoplayVid;
        var firstAutoplayVidElem = '.multi_use_mpp_v1[data-vid-autoplay="autoplay"]:first';
        var $playImg, $playCopy;

        // Handle the first autoplay-chosen MPP video instance on the page.
        function setupAutoPlayVidContent() {
          if ($('.basic_infinite_carouselInner').length) {
            $firstAutoplayVid = $context.find('.basic_infinite_carouselInner').find(firstAutoplayVidElem);
          } else {
            $firstAutoplayVid = $context.find(firstAutoplayVidElem);
          }
          $playImg = $firstAutoplayVid.find('.video_content_cta');
          $playCopy = $firstAutoplayVid.find('.video_content_cta_link__underline');
          // Set the video tag to muted - required for autoplay.
          $firstAutoplayVid.find('video').attr('muted', true);
          // Update the video player mute button.
          $firstAutoplayVid.find('.el_vcv1_vol_icon button').trigger('click');
        }

        // Class available at this point to check if in first slide.
        function autoplayVidChecks(elem) {
          if (elem.parents('.basic_infinite_carousel_slide_first').length) {
            elem.trigger('click');
          }
        }

        // Trigger video playback.
        function autoPlayVidContent() {
          if ($playImg.length) {
            autoplayVidChecks($playImg);
          } else if ($playCopy.length) {
            autoplayVidChecks($playCopy);
          }
        }

        // Handle the image source, pc/mobile
        function mobActions() {
          $('.multi_use_mpp_v1').each(function() {
            var $this = $(this);

            // Update to enable slashes in the Module Redirect URL.
            // Allows for backwards compatability.
            var heroLink = $this.find('.module_block__hero_link');
            if (heroLink.length !== 0) {
              var url = heroLink.attr('href');

              if (url.charAt(1) === '/') {
                heroLink.attr('href', url.substr(1));
              }
            }

            // If IE, handle image sources
            // Check for IE.
            var isIE = false;
            if ($('body').hasClass('ie') || $('body').hasClass('ie-11')) {
              isIE = true;
            }

            /*
            /--------------------------------------------------------------------------
            / Inline Looping Video.
            /--------------------------------------------------------------------------
            / Global vars (for this behaviour).
            / Check if a looping video is present.
            / Video functionality - mute/unmute etc.
            */
            var breakpoint = 768,
              loopingVid = $this.find('.looping_vidContent'),
              muteBtn = $this.find('.auto_vid_vol_icon_src'),
              loopingVidWrapper = loopingVid.closest('.autoplay_video'),
              modPicSrs = loopingVidWrapper.siblings('.module_block__imgs'),
              modBlockImg = modPicSrs.children('img'),
              desktopImgSrsSet = modPicSrs.find('.module_block__imgs_desktop'),
              mobImgSrsSet = modPicSrs.find('.module_block__imgs_mob'),
              hasLoopingVid, mobileVideo, desktopVideo;

            // Check if a looping video is present
            if (loopingVid.attr('data-mob-src') === '' && loopingVid.attr('data-desktop-src') === '') {
              hasLoopingVid = mobileVideo = desktopVideo = false;
            } else if (loopingVid.attr('data-mob-src') === '' && loopingVid.attr('data-desktop-src') !== '') {
              loopingVidWrapper.addClass('destop_auto_loop');
              hasLoopingVid = desktopVideo = true;
              mobileVideo = false;
            } else if (loopingVid.attr('data-mob-src') !== '' && loopingVid.attr('data-desktop-src') === '') {
              loopingVidWrapper.addClass('mob_auto_loop');
              hasLoopingVid = mobileVideo = true;
              desktopVideo = false;
            } else {
              hasLoopingVid = mobileVideo = desktopVideo = true;
            }

            // Begin main video functionality.
            if (hasLoopingVid) {
              loopingVid.each(function() {
                var thisVid = $(this);

                // Add a class to identify
                // that this video is being used
                thisVid.closest('.module_block').addClass('loopingVidActive');

                // If there's no PC/Mobile Module Image loaded,
                // need to adjust the CSS for the video.
                // No PC Module Image
                if (desktopImgSrsSet.attr('srcset') === '' || desktopImgSrsSet.attr('srcset') === 'unknown') {
                  // && no Mobile Module Image
                  if (mobImgSrsSet.attr('srcset') === '' || mobImgSrsSet.attr('srcset') === 'unknown') {
                    modBlockImg.addClass('desktopMobImgSrsSet');
                  } else {
                    // && Mobile Module Image
                    modBlockImg.addClass('desktopImgSrsSet');
                  }
                }

                // Switch the mobile/desktop video sources
                // if needed. Otherwise load video.
                // Need to wait until the metadata is loaded
                var vidSource = thisVid.find('source'),
                  desktopSrcActive = false,
                  mobSrcActive = false,
                  desktopVidSrc = thisVid.data('desktop-src'),
                  mobVidSrc = thisVid.data('mob-src');

                // Need to check if the MPP is in a carousel
                // as will need to update placeholder slide
                // video sources too.
                if (thisVid.closest('.basic_infinite_carouselOuter').length) {
                  if (thisVid.closest('.basic_infinite_carousel_slide').hasClass('basic_infinite_carousel_slide_first')) {
                    thisVid.addClass('parentFirstCarouselSlide');
                  } else if (thisVid.closest('.basic_infinite_carousel_slide').hasClass('basic_infinite_carousel_slide_last')) {
                    thisVid.addClass('parentLastCarouselSlide');
                  }
                }

                function playVideo(video) {
                  var playPromise = video.play();

                  if (!playPromise) {
                    return;
                  }
                  playPromise.catch(function (error) {
                    /* Stop Autoplay for unsupported devices and show the play button.
                    The user can click and play. This is only for unsupported browsers.
                    For all other modern browsers, The autoplay will work properly. */

                    if (error && error.name === 'NotAllowedError') {
                      const $parentElement = $(video).closest('.js-mantle-media-asset, .autoplay_video');

                      if ($parentElement.length > 0) {
                        $parentElement.addClass('mantle-play-icon');
                        $parentElement.on('click', function () {
                          $parentElement.removeClass('mantle-play-icon');
                          video.play();
                        });
                      }
                    }
                  });
                }

                function checkLoad() {
                  var readyState = setInterval(function() {
                    if (thisVid.get(0).readyState > 0) {
                      clearInterval(readyState);

                      // Fade in the video once ready.
                      thisVid.addClass('fadeElemIn');
                      muteBtn.addClass('fadeElemIn');
                      playVideo(thisVid.get(0));
                      modPicSrs.addClass('fadeElemOut');
                    }
                  }, 10);
                }

                // Handle desktop/mobile video source
                // for responsiveness.
                function initVid(src) {
                  if (thisVid.hasClass('parentFirstCarouselSlide')) {
                    thisVid.closest('.basic_infinite_carouselOuter').find('.basic_infinite_carousel_holder').last().find('.looping_vidContent source').attr('src', src);
                  } else if (thisVid.hasClass('parentLastCarouselSlide')) {
                    thisVid.closest('.basic_infinite_carouselOuter').find('.basic_infinite_carousel_holder').first().find('.looping_vidContent source').attr('src', src);
                  }
                  vidSource.attr('src', src);
                  thisVid.get(0).load();
                  checkLoad();
                }

                function handleVidSrc() {
                  if (desktopVideo) {
                    if (mobileVideo) {
                      if ($(window).width() >= breakpoint) {
                        mobSrcActive = false;

                        // Only update if it hasn't already been updated.
                        if (!desktopSrcActive) {
                          desktopSrcActive = true;
                          initVid(desktopVidSrc);
                        } else {
                          return;
                        }
                      } else {
                        desktopSrcActive = false;

                        // Only update if it hasn't already been updated.
                        if (!mobSrcActive) {
                          mobSrcActive = true;
                          initVid(mobVidSrc);
                        } else {
                          return;
                        }
                      }
                    } else {
                      if ($(window).width() >= breakpoint) {
                        modPicSrs.addClass('fadeElemOut');
                        // Only update if it hasn't already been updated.
                        if (!desktopSrcActive) {
                          desktopSrcActive = true;
                          initVid(desktopVidSrc);
                        } else {
                          return;
                        }
                      } else {
                        modPicSrs.removeClass('fadeElemOut');
                        desktopSrcActive = false;
                        initVid('');
                      }
                    }
                  } else {
                    if (mobileVideo) {
                      if ($(window).width() < breakpoint) {
                        modPicSrs.addClass('fadeElemOut');
                        // Only update if it hasn't already been updated.
                        if (!mobSrcActive) {
                          mobSrcActive = true;
                          initVid(mobVidSrc);
                        } else {
                          return;
                        }
                      } else {
                        modPicSrs.removeClass('fadeElemOut');
                        mobSrcActive = false;
                        initVid('');
                      }
                    }
                  }
                }
                // On first load, without resizing event:
                handleVidSrc();

                // Listen for window resizing.
                var resizeTimer;
                $(window).on('resize', function() {
                  // Throttle/ debounce resize event:
                  clearTimeout(resizeTimer);
                  resizeTimer = setTimeout(function() {
                    // Resizing has "stopped"
                    handleVidSrc();
                  }, 10);
                });

                // Mute/unmute volume.
                muteBtn.on('click', function() {
                  muteBtn.toggleClass('muteBtnActive');
                  if (thisVid.get(0).muted === false) {
                    thisVid.get(0).muted = true;
                  } else {
                    thisVid.get(0).muted = false;
                    thisVid.get(0).volume = 0.7;
                  }
                });
              });
            } else {
              loopingVidWrapper.remove();
            }

            // Credit positioning - mobile
            var mobileCredit = false;
            var creditPath = $this.find('.headline_content__credit');
            // Store the initial and updated styles.
            var initialStyles = [];
            var newStyles = [];

            if (creditPath.attr('data-pos-mob-top')) {
              mobileCredit = true;
            }

            function handlePositioning() {
              creditPath.each(function() {
                var initalTopStyle = $(this).prop('style')['top'];
                var initalLeftStyle = $(this).prop('style')['left'];
                initialStyles.push(initalTopStyle, initalLeftStyle);

                var mobTopStyle = $(this).data('pos-mob-top');
                var mobLeftStyle = $(this).data('pos-mob-left');
                newStyles.push(mobTopStyle, mobLeftStyle);
              });
            }
            if (mobileCredit) {
              handlePositioning();
            }

            // Store pc/mobile sources
            function iesrc(eq_val) {
              var theSrc = $this.find('picture source').eq(eq_val);
              var ieSrc = $this.find('picture img');
              var imgSrc = theSrc.attr('srcset');
              ieSrc.attr('src', imgSrc);
            }

            function handleChanges() {
              var insertBeforeSml = $this.find('div.module_block__hero');
              var insertBeforeLrg = $this.find('div.headline_content__sub_content');
              if ($(window).width() < breakpoint) {
                if (isIE) {
                  iesrc(0);
                }
                $this.addClass('mpp_module_wrapper_mobile');
                if ($this.find('.module_block_mpp-template-4').length) {
                  $this.find('.module_block_mpp-template-4 .headline_content__headline').detach().insertBefore(insertBeforeSml);
                }
                if (mobileCredit) {
                  // Only update if it hasn't already been updated.
                  creditPath.each(function() {
                    if ($(this).hasClass('size_mob')) {
                      return;
                    } else {
                      $(this).css('top', newStyles[0]);
                      $(this).css('left', newStyles[1]);
                      $(this).addClass('size_mob').removeClass('size_desktop');
                    }
                  });
                } else {
                  creditPath.addClass('hide_elem');
                }
              } else {
                if (isIE) {
                  iesrc(1);
                }
                $this.removeClass('mpp_module_wrapper_mobile');
                if ($this.find('.module_block_mpp-template-4').length) {
                  $this.find('.module_block_mpp-template-4 .headline_content__headline').detach().insertBefore(insertBeforeLrg);
                }
                if (mobileCredit) {
                  // Only update if it hasn't already been updated.
                  creditPath.each(function() {
                    if ($(this).hasClass('size_desktop')) {
                      return;
                    } else {
                      $(this).css('top', initialStyles[0]);
                      $(this).css('left', initialStyles[1]);
                      $(this).addClass('size_desktop').removeClass('size_mob');
                    }
                  });
                } else {
                  creditPath.removeClass('hide_elem');
                }
              }
            }

            // Listen for window resizing.
            var resizeTimer;
            $(window).on('resize', function() {
              // Throttle/ debounce resize event:
              clearTimeout(resizeTimer);
              resizeTimer = setTimeout(function() {
                // Resizing has "stopped"
                handleChanges();
              }, 10);
            });

            // On first load, without resizing event:
            handleChanges();
          });
        }

        // Wait a second, in case the MPP is in a carousel
        setTimeout(function() {
          mobActions();
        }, 1000);
        setupAutoPlayVidContent();

        // Autoplay the Video Content in window load so that the
        // elements are ready before video playback is triggered.
        $(window).on('load', function() {
          autoPlayVidContent();
        });
      });
    }
  };
})(jQuery);

